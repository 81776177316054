html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
}

h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: var(--text-01-body, #212529);
}

.h5 {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
}

.card {
    border: none !important;
    box-shadow: -2px 3px 11px rgba(0, 0, 0, 0.3);
}

.drop-shadow {
    box-shadow: -2px 3px 11px rgba(0, 0, 0, 0.3);
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
    body {
        color: white;
        background: black;
    }
}

.landing-container {
    /* background-image: url("/Blue_Slant-Top.svg"); */
    background-color: #f1f1f3;
    background-size: cover;
    height: 100vh;
}

.offer-page-list li {
    list-style-type: none;
    margin-bottom: -2em;
}

.offer-page-list li:before {
    content: '+';
    font-weight: bold;
    font-size: 1.75em;
    top: 1.2em;
    left: -1em;
    position: relative;
}

.no-auth-header-container {
    display: flex;
    text-align: start;
    height: 7em;
    background-color: white;
    box-shadow: 5px 0 3px 5px #e4e4e6;
}

.btn-primary {
    background-color: #00aaff !important;
    border-color: #00aaff !important;
}

.btn-outline-primary {
    --bs-btn-color: #00aaff !important;
    --bs-btn-hover-bg: #00aaff !important;
    --bs-btn-border-color: #00aaff !important;
    border-color: #00aaff !important;
}

.card.bg-primary {
    background-color: #00aaff !important;
}

.form-check-input:checked {
    background-color: #00aaff !important;
    border-color: #00aaff !important;
}

.tokensProgress {
    min-width: 12rem;
}

.fs-7 {
    font-size: 12px;
}

.btn {
    border-radius: 10px !important;
    font: 500 14px/21px Montserrat, -apple-system, Roboto, Helvetica, sans-serif !important;
    letter-spacing: -0.3px !important;
}

.btn-sm {
    height: 30px !important;
    padding: 3px 8px !important;
}

.btn-light {
    border: 1px solid var(--components-forms-disabledfill, #e9ecef) !important;
    background-color: var(--components-button-light-default, #fff) !important;
}

.btn-light:hover {
    border: 1px solid var(--components-forms-disabledfill, #e9ecef) !important;
    background-color: var(--components-button-light-hovered, #f8f9fa) !important;
}

.btn.statusActor {
    border-radius: 0px !important;
}

.dropdown-toggle {
    border-radius: 0 !important;
}

.btn-viscap {
    background-color: #ff9800 !important;
    color: white !important;
}

.btn-outline-viscap {
    background-color: transparent !important;
    color: #ff9800 !important;
    border-color: #ff9800 !important;
}
.btn-outline-viscap:hover {
    background-color: #ff9800 !important;
    color: white !important;
}
.btn-viscap:hover {
    background-color: #c57702 !important;
    color: white;
}

.btn-transparent {
    background-color: rgba(255, 255, 255, 0.5);
}

.btn-transparent:disabled {
    border: none;
}

.admin-container {
    display: flex;
    height: 100vh;
    overflow-y: hidden;
}

.navbar {
    justify-content: flex-start !important;
}

.admin-nav-expanded {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: fixed;
    height: 100vh;
    top: 0;
    border-right: 1px solid lightgray;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    justify-content: space-between !important;
}

.admin-nav-expanded h6 {
    cursor: pointer;
}

.admin-nav-expanded img {
    height: 1rem;
}

.nav-option {
    width: 100%;
}

.nav-options-group {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    width: 100%;
    color: gray;
    border-top: 1px solid lightgray;
    border-bottom: none;
}

.admin-nav-collapsed {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 4.1rem;
    text-align: center;
    background-color: #fff;
    position: fixed;
    height: 100%;
    border-right: 1px solid lightgrey;
    top: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.fit-content {
    width: fit-content;
}

.flex-grow {
    flex-grow: 1;
}

.admin-nav h3 {
    margin: 0;
}

.admin-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
}

.mt-1 {
    padding-top: 1em;
}

.admin-header {
    flex: 1;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    min-width: 1200px;
}

.admin-header h3 {
    font-weight: 700;
}

.user-icon {
    font-size: 2.5em;
}

.help-button {
    margin-right: 1em;
}

.admin-navbar-toggler {
    width: 100%;
}

.space-between {
    justify-content: space-between !important;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.justify-center {
    justify-content: center !important;
}

.tab-content {
    height: 100%;
}

@media screen and (max-width: 500px) {
    .admin-nav {
        width: 1200px;
        height: 3em;
        padding: 0 !important;
        align-items: flex-start !important;
    }
    .admin-container {
        flex-direction: column;
    }
    .mt-1 {
        display: none;
    }
    .admin-navbar-toggler {
        width: 3em;
        border: none;
        display: inline-block;
        position: relative;
        padding: 0;
        margin-left: 0.5em;
    }
    .admin-navbar-toggler {
        position: absolute;
        height: 100%;
    }
    .admin-navbar-toggler span {
        height: 100% !important;
    }
}

.divider {
    border-bottom: 1px solid lightgrey;
    width: 60%;
    padding-bottom: 1em;
}

.admin-main {
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.story-tabheader p {
    margin-bottom: 0;
    border-right: 1px solid lightgray;
    padding: 0.5rem 0.5rem 0.5rem 0.25rem;
}

.story-tabheader {
    background-color: white;
    justify-content: space-evenly;
    position: sticky;
    z-index: 2;
    font-weight: 700;
}

.story-subheader {
    background-color: grey;
    justify-content: space-evenly;
    position: sticky;
    z-index: 2;
    min-width: 1200px;
    align-items: center;
}

.story-subheader > * > * > .btn-transparent {
    color: white;
    animation: none;
}

.story-subheader > * > * > .btn-transparent:hover {
    color: rgb(253, 255, 121);
}

.story-subheader > * > * > .btn-transparent:focus {
    color: rgb(253, 255, 121);
}

.userProfileMenu button {
    margin: 0;
    padding: 0;
    border: 0 !important;
}

.datalist {
    position: relative;
    width: 100%;
}

.datalist ul {
    position: absolute;
    z-index: 4;
    background-color: #fff;
    width: 100%;
    padding: 0;
    box-shadow: -2px 3px 11px rgba(0, 0, 0, 0.3);
}

.datalist ul li {
    list-style: none;
    border-bottom: 1px solid gray;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.datalist input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
}

.no-arrow .dropdown-toggle::after {
    display: none !important;
}

.alert {
    position: fixed !important;
    bottom: 1rem;
    margin: 0 !important;
    right: 1rem;
    width: fit-content;
    z-index: 999;
}

.switch-label {
    font-size: 14px;
    margin: 0 !important;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-link {
    background-color: white !important;
    color: gray !important;
    border-radius: 0 !important;
}

.nav-tabs .nav-link.active {
    background-color: #f8f9fa !important;
    color: black !important;
    border-radius: 0 !important;
    border-bottom: none;
}

.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    font-family: Montserrat, sans-serif;
    line-height: 1.125em;
    height: auto;
}

.checkbox-mt-0 input {
    margin: 0;
}

.no-drag {
    user-select: none;
    -webkit-user-drag: none;
}

.lessontabs .nav-link.active {
    color: #00aaff !important;
    border: 0;
    border-bottom: 2px solid #00aaff !important;
}

.lessontabs .nav-link {
    margin-right: 1.5rem;
    font-weight: 600;
    color: #9b9b9b !important;
}

.alert {
    margin-right: 5rem !important;
}

#hubspot-messages-iframe-container {
    color-scheme: light;
}

.markdown > table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
}

.markdown > table thead tr {
    background-color: #505050;
    color: #ffffff;
    text-align: left;
}

.markdown > table th,
.markdown > table td {
    padding: 12px 15px;
}

.markdown > table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.markdown > table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.markdown > table tbody tr:last-of-type {
    border-bottom: 2px solid #505050;
}

.initials-avatar {
    width: 7rem;
    height: 7rem;
    cursor: pointer;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #ff9800;
    display: inline-block;
    box-sizing: border-box;
}

.initials-avatar-comment {
    width: 3rem;
    height: 3rem;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #ff9800;
    display: inline-block;
    box-sizing: border-box;
}

.initials-avatar-comment div {
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: inherit;
    font-weight: inherit;
    font-size: 1.2rem;
}

.initials-avatar div {
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: inherit;
    font-weight: inherit;
    font-size: 2.5rem;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.no-toggle .dropdown-toggle:after {
    display: none !important;
}

.view-active {
    border-bottom: 4px solid #00aaff !important;
}

.view {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    vertical-align: middle;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid lightgray;
    font-size: 12px;
}

.add-view {
    background-color: gray;
    color: white;
    font-size: 12px;
}

.small-input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #979797;
}

a.dropdown-submenu {
    cursor: pointer;
}

.lessoncard .tab-content {
    height: 100%;
}

.color-gray-80 {
    color: #808080 !important;
}

.react-thumbnail-generator {
    width: 130px;
    height: 130px;
    border-radius: 1rem;
}

.react-thumbnail-generator img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.carousel-control-prev,
.carousel-control-next {
    height: fit-content !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

img.thumbnail-selected {
    border: 5px solid #00aaff !important;
}

.thumbnail-selected .react-thumbnail-generator img {
    border: 5px solid #00aaff !important;
}

.talent-footer {
    position: sticky;
    bottom: 0;
}

.fs-14 {
    font-size: 14px;
}

.nowrap {
    white-space: nowrap;
}

.h-24 {
    height: 24px;
}

.w-20 {
    width: 20%;
}

.card-selected {
    border: 3px solid rgba(0, 170, 255, 0.5);
}

.smallInput {
    font-size: 14px !important;
}

.creativeSelect {
    color: #000;
    font-size: 13px;
}

.break-all {
    word-break: break-all;
}

.testDiv span {
    position: relative !important;
    height: 100% !important;
    width: auto !important;
}

.testDiv span img {
    position: relative !important;
    height: 100% !important;
    width: auto !important;
}

.h-0 {
    height: 0px !important;
}

.no-click {
    pointer-events: none;
}

[class^='ant-menu'] {
    font-weight: 400 !important;
    font-style: normal !important;
}

.hideCalendarHeader .ant-picker-calendar-header {
    display: none;
}

.hideCalendarHeader .ant-picker-panel {
    border-top: none;
}

.border-blue {
    border: 2px solid #00aaff;
    border-radius: 8px;
}

.ant-collapse-header {
    align-items: center !important;
}

.ant-tag {
    display: inline-flex;
    align-items: center;

    > * {
        &:nth-child(1) {
            flex-shrink: 0;
        }

        &:nth-child(2) {
            max-width: calc(100% - 7px - 12px);
            margin-left: 7px;
        }
    }
}

.ant-dropdown-menu-item {
    > svg {
        min-width: 14px;
        margin-inline-end: 8px;
        font-size: 12px;
    }
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

::-webkit-scrollbar-track {
    background: 0;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.cc-widget-outer-container {
    visibility: hidden;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev:hover {
    left: 10px;
    z-index: 2;
    color: darkgray !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
    color: darkgray !important;
}